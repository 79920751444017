define("ember-parachute/decorators/query-param", ["exports", "ember-parachute/-private/symbols", "ember-parachute/decorators/-private/query-params-for"], function (_exports, _symbols, _queryParamsFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = queryParam;

  function createDescriptor(desc, qpDefinition) {
    qpDefinition = qpDefinition || {};
    const descriptor = { ...desc,

      finisher(klass) {
        (0, _queryParamsFor.addQueryParamFor)(klass, desc.key, qpDefinition);
        klass.reopen((0, _queryParamsFor.getQueryParamsFor)(klass).Mixin);
        const proto = klass.proto(); // Remove duplicate queryParams created by the multiple mixins

        if (Array.isArray(proto.queryParams)) {
          const queryParams = Ember.A([...proto.queryParams]);
          const parachuteQueryParams = queryParams.filterBy(_symbols.PARACHUTE_QPS, true); // Keep the newest one

          parachuteQueryParams.pop(); // Remove the old ones

          queryParams.removeObjects(parachuteQueryParams);
          proto.queryParams = queryParams.toArray();
        }

        return klass;
      }

    };

    if (desc.kind === 'field') {
      if (typeof desc.initializer === 'function') {
        qpDefinition.defaultValue = desc.initializer();
      }

      descriptor.initializer = function initializer() {
        return qpDefinition.defaultValue;
      };
    }

    return descriptor;
  }

  function queryParam(qpDefinition) {
    // Handle `@queryParam` usage
    if ("".concat(qpDefinition) === '[object Descriptor]') {
      return createDescriptor(qpDefinition);
    } // Handle `@queryParam()` usage


    return desc => createDescriptor(desc, qpDefinition);
  }
});