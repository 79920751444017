define("ember-changeset/-private/change", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    class Change {
        constructor(value) {
            this.value = value;
        }
    }
    exports.default = Change;
});