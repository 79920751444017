define("ember-leaflet-marker-cluster/instance-initializers/register-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let emberLeafletService = appInstance.lookup('service:ember-leaflet');

    if (emberLeafletService) {
      emberLeafletService.registerComponent('marker-cluster-layer', {
        as: 'marker-cluster'
      });
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});