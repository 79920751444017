define('ember-changeset/utils/computed/object-to-array', ['exports', 'ember-changeset/utils/is-object'], function (exports, _isObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = objectToArray;

    const { keys } = Object;
    /**
     * Compute the array form of an object.
     *
     * Each value of the object is transformed by a passed-in `transform`
     * function.
     *
     * Returns a list of objects, where each object has the form
     * `{ key, value }`. If `flattenObjects` is true and the result of
     * `transform` is an Object, the resulting object has the form
     * `{ key, ...transformResult }`.
     */
    function objectToArray(objKey, transform = a => a, flattenObjects = false) {
        return Ember.computed(objKey, function () {
            let obj = Ember.get(this, objKey);
            return keys(obj).map(key => {
                let value = transform(obj[key]);
                if (flattenObjects && (0, _isObject.default)(value)) {
                    return Ember.assign({ key }, value);
                }
                return { key, value };
            });
        }).readOnly();
    }
});