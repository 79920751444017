define('ember-changeset/utils/includes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = includes;
    function includes(arr, ...args) {
        (false && !(Ember.typeOf(arr) === 'array') && Ember.assert('must be array', Ember.typeOf(arr) === 'array'));

        const wrapped = Ember.A(arr);
        const inclusionFn = wrapped.includes || wrapped.contains;
        return inclusionFn.apply(arr, args);
    }
});