define('ember-burger-menu/animations/squeeze', ['exports', 'ember-burger-menu/animations/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    animation: 'squeeze',

    outlet(open, width, right) {
      return {
        marginLeft: open && !right ? `${width}px` : 0,
        marginRight: open && right ? `${width}px` : 0
      };
    }
  });
});