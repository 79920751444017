define('ember-local-storage/mixins/array', ['exports', 'ember-local-storage/mixins/storage', 'ember-local-storage/helpers/utils'], function (exports, _storage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    A,
    Mixin,
    set
  } = Ember;

  exports.default = Mixin.create(_storage.default, {
    _initialContent: A(),
    _clear() {
      set(this, 'content', Ember.A());
    },

    replaceContent: _utils.save,
    reset: _utils.save
  });
});