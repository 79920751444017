define('ember-local-storage/mixins/object', ['exports', 'ember-local-storage/mixins/storage', 'ember-local-storage/helpers/utils'], function (exports, _storage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    Mixin,
    set
  } = Ember;

  exports.default = Mixin.create(_storage.default, {
    _initialContent: {},
    _clear() {
      set(this, 'content', {});
    },

    setUnknownProperty: _utils.saveIfChanged,
    set: _utils.saveIfChanged,
    setProperties: _utils.save
  });
});