define('ember-changeset/utils/computed/transform', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = transform;

    const { keys } = Object;
    /**
     * Transform an Object's values with a `transform` function.
     */
    function transform(dependentKey, transform) {
        return Ember.computed(dependentKey, function () {
            let obj = Ember.get(this, dependentKey);
            return keys(obj).reduce((newObj, key) => {
                newObj[key] = transform(obj[key]);
                return newObj;
            }, {});
        });
    }
});