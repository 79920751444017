define("ember-lifeline/run-task", ["exports", "ember-lifeline/utils/disposable", "ember-lifeline/utils/get-task"], function (_exports, _disposable, _getTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._setRegisteredTimers = _setRegisteredTimers;
  _exports.runTask = runTask;
  _exports.scheduleTask = scheduleTask;
  _exports.throttleTask = throttleTask;
  _exports.cancelTask = cancelTask;

  /**
   * A map of instances/timers that allows us to
   * store cancelIds for scheduled timers per instance.
   *
   * @private
   *
   */
  let registeredTimers = new WeakMap();
  /**
   * Test use only. Allows for swapping out the WeakMap to a Map, giving
   * us the ability to detect whether the timers set is empty.
   *
   * @private
   * @param {*} mapForTesting A map used to ensure correctness when testing.
   */

  function _setRegisteredTimers(mapForTesting) {
    registeredTimers = mapForTesting;
  }
  /**
     Registers and runs the provided task function for the provided object at the specified
     timeout (defaulting to 0). The timer is properly canceled if the object is destroyed
     before it is invoked.
  
     Example:
  
     ```js
     import Component from 'ember-component';
     import { runTask, runDisposables } from 'ember-lifeline';
  
     export default Component.extend({
       didInsertElement() {
         runTask(this, () => {
           console.log('This runs after 5 seconds if this component is still displayed');
         }, 5000)
       },
  
       willDestroy() {
         this._super(...arguments);
  
         runDisposables(this);
       }
     });
     ```
  
     @function runTask
     @param { IDestroyable } destroyable the instance to register the task for
     @param { Function | String } taskOrName a function representing the task, or string
                                             specifying a property representing the task,
                                             which is run at the provided time specified
                                             by timeout
     @param { Number } [timeout=0] the time in the future to run the task
     @public
     */


  function runTask(destroyable, taskOrName, timeout = 0) {
    (false && !(!destroyable.isDestroyed) && Ember.assert("Called `runTask` on destroyed object: ".concat(destroyable, "."), !destroyable.isDestroyed));
    let task = (0, _getTask.default)(destroyable, taskOrName, 'runTask');
    let timers = getTimers(destroyable);
    let cancelId = Ember.run.later(() => {
      timers.delete(cancelId);
      task.call(destroyable);
    }, timeout);
    timers.add(cancelId);
    return cancelId;
  }
  /**
     Adds the provided function to the named queue for the provided object. The timer is
     properly canceled if the object is destroyed before it is invoked.
  
     Example:
  
     ```js
     import Component from 'ember-component';
     import { scheduleTask, runDisposables } from 'ember-lifeline';
  
     export default Component.extend({
       init() {
         this._super(...arguments);
  
         scheduleTask(this, 'actions', () => {
           console.log('This runs at the end of the run loop (via the actions queue) if this component is still displayed');
         })
       },
  
       willDestroy() {
         this._super(...arguments);
  
         runDisposables(this);
       }
     });
     ```
  
     @function scheduleTask
     @param { IDestroyable } destroyable the instance to register the task for
     @param { String } queueName the queue to schedule the task into
     @param { Function | String } taskOrName a function representing the task, or string
                                             specifying a property representing the task,
                                             which is run at the provided time specified
                                             by timeout
     @param { ...* } args arguments to pass to the task
     @public
     */


  function scheduleTask(destroyable, queueName, taskOrName, ...args) {
    (false && !(typeof queueName === 'string') && Ember.assert("Called `scheduleTask` without a string as the first argument on ".concat(destroyable, "."), typeof queueName === 'string'));
    (false && !(queueName !== 'afterRender') && Ember.assert("Called `scheduleTask` while trying to schedule to the `afterRender` queue on ".concat(destroyable, "."), queueName !== 'afterRender'));
    (false && !(!destroyable.isDestroyed) && Ember.assert("Called `scheduleTask` on destroyed object: ".concat(destroyable, "."), !destroyable.isDestroyed));
    let task = (0, _getTask.default)(destroyable, taskOrName, 'scheduleTask');
    let timers = getTimers(destroyable);
    let cancelId;

    let taskWrapper = (...taskArgs) => {
      timers.delete(cancelId);
      task.call(destroyable, ...taskArgs);
    };

    cancelId = Ember.run.schedule(queueName, destroyable, taskWrapper, ...args);
    timers.add(cancelId);
    return cancelId;
  }
  /**
     Runs the function with the provided name immediately, and only once in the time window
     specified by the spacing argument.
  
     Example:
  
     ```js
     import Component from 'ember-component';
     import { throttleTask, runDisposables } from 'ember-lifeline';
  
     export default Component.extend({
       logMe() {
         console.log('This will run once immediately, then only once every 300ms.');
       },
  
       click() {
         throttleTask(this, 'logMe', 300);
       },
  
       destroy() {
         this._super(...arguments);
  
         runDisposables(this);
       }
     });
     ```
  
     @method throttleTask
     @param { IDestroyable } destroyable the instance to register the task for
     @param { String } taskName the name of the task to throttle
     @param { ...* } [throttleArgs] arguments to pass to the throttled method
     @param { Number } spacing the time in the future to run the task
     @param { Boolean } [immediate] Trigger the function on the leading instead of the trailing edge of the wait interval. Defaults to true.
     @public
     */


  function throttleTask(destroyable, taskName, ...throttleArgs) {
    (false && !(typeof taskName === 'string') && Ember.assert("Called `throttleTask` without a string as the first argument on ".concat(destroyable, "."), typeof taskName === 'string'));
    (false && !(typeof destroyable[taskName] === 'function') && Ember.assert("Called `throttleTask('".concat(taskName, "')` where '").concat(taskName, "' is not a function."), typeof destroyable[taskName] === 'function'));
    (false && !(!destroyable.isDestroyed) && Ember.assert("Called `throttleTask` on destroyed object: ".concat(destroyable, "."), !destroyable.isDestroyed));
    const lastArgument = throttleArgs[throttleArgs.length - 1];
    const spacing = typeof lastArgument === 'boolean' ? throttleArgs[throttleArgs.length - 2] : lastArgument;
    (false && !(typeof spacing === 'number') && Ember.assert("Called `throttleTask` with incorrect `spacing` argument. Expected Number and received `".concat(spacing, "`"), typeof spacing === 'number'));
    let timers = getTimers(destroyable);
    let cancelId = Ember.run.throttle(destroyable, taskName, ...throttleArgs);
    timers.add(cancelId);
    return cancelId;
  }

  function cancelTask(destroyable, cancelId) {
    if (typeof cancelId === 'undefined') {
      (false && !(true) && Ember.deprecate('ember-lifeline cancelTask called without an object. New syntax is cancelTask(destroyable, cancelId) and avoids a memory leak.', true, {
        id: 'ember-lifeline-cancel-task-without-object',
        until: '4.0.0'
      }));
      cancelId = destroyable;
    } else {
      let timers = getTimers(destroyable);
      timers.delete(cancelId);
    }

    Ember.run.cancel(cancelId);
  }

  function getTimersDisposable(destroyable, timers) {
    return function () {
      timers.forEach(cancelId => {
        cancelTask(destroyable, cancelId);
      });
      timers.clear();
    };
  }

  function getTimers(destroyable) {
    let timers = registeredTimers.get(destroyable);

    if (!timers) {
      timers = new Set();
      registeredTimers.set(destroyable, timers);
      (0, _disposable.registerDisposable)(destroyable, getTimersDisposable(destroyable, timers));
    }

    return timers;
  }
});