define('ember-burger-menu/mixins/swipe-support', ['exports', 'ember-jquery-legacy'], function (exports, _emberJqueryLegacy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let meta;

  exports.default = Ember.Mixin.create({
    minSwipeDistance: Ember.computed.alias('state.minSwipeDistance'),
    maxSwipeTime: Ember.computed.alias('state.maxSwipeTime'),

    onSwipe() /* direction, target */{},

    touchStart(e) {
      let nativeEvent = (0, _emberJqueryLegacy.normalizeEvent)(e);
      this._super(...arguments);

      // jscs:disable
      let touch = nativeEvent.touches[0];
      // jscs:enable

      meta = {
        target: e.target,
        start: {
          x: touch.pageX,
          y: touch.pageY,
          time: new Date().getTime()
        }
      };
    },

    touchMove(e) {
      let nativeEvent = (0, _emberJqueryLegacy.normalizeEvent)(e);
      this._super(...arguments);

      // jscs:disable
      let touch = nativeEvent.touches[0];
      // jscs:enable

      meta.differences = {
        x: touch.pageX - meta.start.x,
        y: touch.pageY - meta.start.y
      };

      // Compute swipe direction
      if (Ember.isNone(meta.isHorizontal)) {
        meta.isHorizontal = Math.abs(meta.differences.x) > Math.abs(meta.differences.y);
      }

      // A valid swipe event uses only one finger
      if (nativeEvent.touches.length > 1) {
        meta.isInvalid = true;
      }
    },

    touchEnd() {
      this._super(...arguments);

      let minSwipeDistance = this.get('minSwipeDistance');
      let maxSwipeTime = this.get('maxSwipeTime');
      let elapsedTime = new Date().getTime() - meta.start.time;

      if (meta.isHorizontal && !meta.isInvalid && Math.abs(meta.differences.x) >= minSwipeDistance && elapsedTime <= maxSwipeTime) {
        this.onSwipe(meta.differences.x > 0 ? 'right' : 'left', meta.target);
      }
    }
  });
});