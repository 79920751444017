define('ember-local-storage/adapters/session', ['exports', 'ember-local-storage/adapters/base', 'ember-local-storage/helpers/storage', 'ember-local-storage/session/array'], function (exports, _base, _storage, _array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    get
  } = Ember;

  exports.default = _base.default.extend({
    _storage: (0, _storage.getStorage)('session'),

    _getIndex(type) {
      const indices = get(this, '_indices');

      if (!indices[type]) {
        indices[type] = _array.default.extend({ _storageKey: 'index-' + type }).create();
      }

      return indices[type];
    }
  });
});