define("ember-table/components/ember-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xazfW9R4",
    "block": "[[[10,0],[14,0,\"ember-table-overflow\"],[15,1,[29,[[36,0],\"-overflow\"]]],[12],[1,\"\\n  \"],[10,\"table\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,2],null,[[\"api\",\"head\",\"body\",\"foot\"],[[33,3],[50,\"ember-thead\",0,null,[[\"api\"],[[33,3]]]],[50,\"ember-tbody\",0,null,[[\"api\"],[[33,3]]]],[50,\"ember-tfoot\",0,null,[[\"api\"],[[33,3]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[35,5],null,[[\"api\"],[[33,3]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"elementId\",\"yield\",\"hash\",\"api\",\"component\",\"-ember-table-private/scroll-indicators\"]]",
    "moduleName": "ember-table/components/ember-table/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});