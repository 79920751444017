define('ember-burger-menu/utils/css-stringify', ['exports', '@ember/string'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = cssStringify;


  const PREFIXES = ['webkit'];
  const PREFIXED_PROPS = ['transition', 'transition-timing-function', 'transition-property', 'transition-speed', 'transition-delay', 'transform', 'transform-style', 'transform-origin', 'perspective', 'perspective-origin'];

  function cssStringify(hash = {}) {
    return Ember.String.htmlSafe(Object.keys(hash).reduce((css, key) => {
      let value = hash[key];

      if (!Ember.isEmpty(value)) {
        css = css.concat(buildProp(key, value));
      }

      return css;
    }, []).join('; '));
  }

  function buildProp(key, value) {
    key = (0, _string.dasherize)(key);
    let css = [`${key}: ${value}`];

    if (PREFIXED_PROPS.indexOf(key) > -1) {
      PREFIXES.forEach(p => {
        css.push(`-${p}-${key}: ${value}`);
      });
    }

    return css;
  }
});