define('ember-burger-menu/animations/menu-item/stack', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    itemAnimation: 'stack',

    menuItem(open, width, right, index) {
      return {
        transform: open ? '' : `translate3d(0, ${(index + 1) * 500}px, 0)`
      };
    }
  });
});