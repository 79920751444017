define('ember-burger-menu/utils/closest', ['exports', 'matches-selector'], function (exports, _matchesSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = closest;
  function closest(el, selector, checkSelf) {
    let parent = checkSelf ? el : el.parentNode;

    while (parent && parent !== document) {
      if ((0, _matchesSelector.default)(parent, selector)) {
        return parent;
      } else {
        parent = parent.parentNode;
      }
    }
  }
});