define("ember-changeset/-private/err", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    class Err {
        constructor(value, validation) {
            this.value = value;
            this.validation = validation;
        }
    }
    exports.default = Err;
});