define("ember-leaflet/components/polygon-layer", ["exports", "ember-leaflet/components/polyline-layer"], function (_exports, _polylineLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A class for drawing polygon overlays on a map. Extends Polyline.
   * Note that points you pass when creating a polygon shouldn't have
   * an additional last point equal to the first one — it's better to filter out such points.
   *
   * @class PolygonLayer
   * @extends PolylineLayer
   */
  class PolygonLayer extends _polylineLayer.default {
    createLayer() {
      return this.L.polygon(...this.requiredOptions, this.options);
    }

  }

  _exports.default = PolygonLayer;
});