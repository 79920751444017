define("ember-leaflet/helpers/ember-leaflet-hash", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberLeafletHash = emberLeafletHash;
  _exports.default = void 0;

  function emberLeafletHash(_, named) {
    return Object.assign({}, named);
  }

  var _default = (0, _helper.helper)(emberLeafletHash);

  _exports.default = _default;
});