define('liquid-tether/components/liquid-tether', ['exports', '@ember/string', 'liquid-wormhole/components/liquid-wormhole'], function (exports, _string, _liquidWormhole) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _liquidWormhole.default.extend({
    classPrefix: 'liquid-tether',
    target: null,
    attachment: null,
    targetAttachment: null,
    offset: null,
    targetOffset: null,
    targetModifier: null,
    constraints: null,
    optimizations: null,

    didInsertElement() {
      this._super.apply(this, arguments);

      this._tetherElement = this.get('nodes')[0];
    },

    willAppendNodes(bodyElement) {
      if (this._tether) {
        this.removeTether();
      }

      this.addTether(bodyElement);
    },

    didAppendNodes() {
      this._tether.position();
    },

    willRemoveNodes() {
      this._tether.position();
    },

    willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.run.schedule('render', () => {
        this.removeTether();
      });
    },

    addTether(bodyElement) {
      const target = this.get('_tetherTarget');

      const element = this._tetherElement;

      const options = { element, target, bodyElement };

      ['classPrefix', 'attachment', 'targetAttachment', 'offset', 'targetOffset', 'targetModifier', 'constraints', 'optimizations'].forEach(k => {
        const v = Ember.get(this, k);
        if (!Ember.isNone(v)) {
          options[(0, _string.camelize)(k)] = v;
        }
      });

      this._tether = new Tether(options);
    },

    removeTether() {
      if (this._tether) {
        this._tether.destroy();
      }
    },

    _tetherTarget: Ember.computed('target', function () {
      let target = Ember.get(this, 'target');

      if (target && target.element) {
        return target.element;
      } else if (target === 'document.body') {
        return document.body;
      }

      (false && !(target instanceof Element || document.querySelector(target) !== null) && Ember.assert(`Tether target "${target}" does not exist in the document`, target instanceof Element || document.querySelector(target) !== null));


      return target;
    }),

    actions: {
      clickOverlay() {
        if (this.get('on-overlay-click')) {
          // eslint-disable-next-line
          this.sendAction('on-overlay-click');
        }
      }
    }
  });
});