define('ember-changeset/index', ['exports', 'ember-changeset/-private/change', 'ember-changeset/-private/err', 'ember-changeset/utils/assign', 'ember-changeset/utils/computed/inflate', 'ember-changeset/utils/computed/is-empty-object', 'ember-changeset/utils/computed/object-to-array', 'ember-changeset/utils/computed/transform', 'ember-changeset/utils/includes', 'ember-changeset/utils/is-changeset', 'ember-changeset/utils/is-object', 'ember-changeset/utils/is-promise', 'ember-changeset/utils/merge-nested', 'ember-changeset/utils/object-without', 'ember-changeset/utils/set-nested-property', 'ember-changeset/utils/take', 'ember-changeset/utils/validate-nested-obj', 'ember-deep-set'], function (exports, _change, _err, _assign, _inflate, _isEmptyObject, _objectToArray, _transform, _includes, _isChangeset, _isObject, _isPromise, _mergeNested, _objectWithout, _setNestedProperty, _take, _validateNestedObj, _emberDeepSet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.changeset = changeset;

    const { keys } = Object;
    const CONTENT = '_content';
    const CHANGES = '_changes';
    const ERRORS = '_errors';
    const VALIDATOR = '_validator';
    const OPTIONS = '_options';
    const RUNNING_VALIDATIONS = '_runningValidations';
    const BEFORE_VALIDATION_EVENT = 'beforeValidation';
    const AFTER_VALIDATION_EVENT = 'afterValidation';
    const AFTER_ROLLBACK_EVENT = 'afterRollback';
    const defaultValidatorFn = () => true;
    const defaultOptions = { skipValidate: false };
    /**
     * Creates new changesets.
     *
     * @uses Ember.Evented
     */
    function changeset(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
        (false && !(Ember.isPresent(obj)) && Ember.assert('Underlying object for changeset is missing', Ember.isPresent(obj)));

        let changeset = {
            // notifyPropertyChange: (s: string) => void,
            // trigger: (k: string, v: string | void) => void,
            __changeset__: _isChangeset.CHANGESET,
            _content: {},
            _changes: {},
            _errors: {},
            _validator: defaultValidatorFn,
            _options: defaultOptions,
            _runningValidations: {},
            _bareChanges: (0, _transform.default)(CHANGES, c => c.value),
            changes: (0, _objectToArray.default)(CHANGES, c => c.value, false),
            errors: (0, _objectToArray.default)(ERRORS, e => ({ value: e.value, validation: e.validation }), true),
            change: (0, _inflate.default)(CHANGES, c => c.value),
            error: (0, _inflate.default)(ERRORS, e => ({ value: e.value, validation: e.validation })),
            data: Ember.computed.readOnly(CONTENT),
            isValid: (0, _isEmptyObject.default)(ERRORS),
            isPristine: (0, _isEmptyObject.default)(CHANGES),
            isInvalid: Ember.computed.not('isValid').readOnly(),
            isDirty: Ember.computed.not('isPristine').readOnly(),
            init() {
                let c = this;
                c._super(...arguments);
                c[CONTENT] = obj;
                c[CHANGES] = {};
                c[ERRORS] = {};
                c[VALIDATOR] = validateFn;
                c[OPTIONS] = (0, _assign.default)(defaultOptions, options);
                c[RUNNING_VALIDATIONS] = {};
            },
            /**
             * Proxies `get` to the underlying content or changed value, if present.
             */
            unknownProperty(key) {
                return this._valueFor(key);
            },
            /**
             * Stores change on the changeset.
             *
             * @method setUnknownProperty
             */
            setUnknownProperty(key, value) {
                let config = Ember.get(this, OPTIONS);
                let skipValidate = Ember.get(config, 'skipValidate');
                if (skipValidate) {
                    let content = Ember.get(this, CONTENT);
                    let oldValue = Ember.get(content, key);
                    this._setProperty({ key, value, oldValue });
                    return this._handleValidation(true, { key, value });
                }
                let content = Ember.get(this, CONTENT);
                let oldValue = Ember.get(content, key);
                this._setProperty({ key, value, oldValue });
                return this._validateKey(key, value);
            },
            /**
             * String representation for the changeset.
             */
            toString() {
                let normalisedContent = (0, _assign.default)(Ember.get(this, CONTENT), {});
                return `changeset:${normalisedContent.toString()}`;
            },
            /**
             * Provides a function to run before emitting changes to the model. The
             * callback function must return a hash in the same shape:
             *
             * ```
             * changeset
             *   .prepare((changes) => {
             *     let modified = {};
             *
             *     for (let key in changes) {
             *       modified[underscore(key)] = changes[key];
             *     }
             *
             *    return modified; // { first_name: "Jim", last_name: "Bob" }
             *  })
             *  .execute(); // execute the changes
             * ```
             *
             * @method prepare
             */
            prepare(prepareChangesFn) {
                let changes = Ember.get(this, '_bareChanges');
                let preparedChanges = prepareChangesFn(changes);
                (false && !((0, _isObject.default)(preparedChanges)) && Ember.assert('Callback to `changeset.prepare` must return an object', (0, _isObject.default)(preparedChanges)));

                (0, _validateNestedObj.default)('preparedChanges', preparedChanges);
                let newObj = {};
                let newChanges = keys(preparedChanges).reduce((newObj, key) => {
                    newObj[key] = new _change.default(preparedChanges[key]);
                    return newObj;
                }, newObj);
                Ember.set(this, CHANGES, newChanges);
                return this;
            },
            /**
             * Executes the changeset if in a valid state.
             *
             * @method execute
             */
            execute() {
                if (Ember.get(this, 'isValid') && Ember.get(this, 'isDirty')) {
                    let content = Ember.get(this, CONTENT);
                    let changes = Ember.get(this, CHANGES);
                    keys(changes).forEach(key => (0, _emberDeepSet.default)(content, key, changes[key].value));
                }
                return this;
            },
            /**
             * Executes the changeset and saves the underlying content.
             *
             * @method save
             * @param {Object} options optional object to pass to content save method
             */
            save(options) {
                let content = Ember.get(this, CONTENT);
                let savePromise = Ember.RSVP.resolve(this);
                this.execute();
                if (typeof content.save === 'function') {
                    savePromise = content.save(options);
                } else if (typeof Ember.get(content, 'save') === 'function') {
                    // we might be getting this off a proxy object.  For example, when a
                    // belongsTo relationship (a proxy on the parent model)
                    // another way would be content(belongsTo).content.save
                    let saveFunc = Ember.get(content, 'save');
                    if (saveFunc) {
                        savePromise = saveFunc(options);
                    }
                }
                return Ember.RSVP.resolve(savePromise).then(result => {
                    this.rollback();
                    return result;
                });
            },
            /**
             * Merges 2 valid changesets and returns a new changeset. Both changesets
             * must point to the same underlying object. The changeset target is the
             * origin. For example:
             *
             * ```
             * let changesetA = new Changeset(user, validatorFn);
             * let changesetB = new Changeset(user, validatorFn);
             * changesetA.set('firstName', 'Jim');
             * changesetB.set('firstName', 'Jimmy');
             * changesetB.set('lastName', 'Fallon');
             * let changesetC = changesetA.merge(changesetB);
             * changesetC.execute();
             * user.get('firstName'); // "Jimmy"
             * user.get('lastName'); // "Fallon"
             * ```
             *
             * @method merge
             */
            merge(changeset) {
                let content = Ember.get(this, CONTENT);
                (false && !((0, _isChangeset.default)(changeset)) && Ember.assert('Cannot merge with a non-changeset', (0, _isChangeset.default)(changeset)));
                (false && !(Ember.get(changeset, CONTENT) === content) && Ember.assert('Cannot merge with a changeset of different content', Ember.get(changeset, CONTENT) === content));

                if (Ember.get(this, 'isPristine') && Ember.get(changeset, 'isPristine')) {
                    return this;
                }
                let c1 = Ember.get(this, CHANGES);
                let c2 = Ember.get(changeset, CHANGES);
                let e1 = Ember.get(this, ERRORS);
                let e2 = Ember.get(changeset, ERRORS);
                let newChangeset = new Changeset(content, Ember.get(this, VALIDATOR)); // ChangesetDef
                let newErrors = (0, _objectWithout.default)(keys(c2), e1);
                let newChanges = (0, _objectWithout.default)(keys(e2), c1);
                let mergedErrors = (0, _mergeNested.default)(newErrors, e2);
                let mergedChanges = (0, _mergeNested.default)(newChanges, c2);
                newChangeset[ERRORS] = mergedErrors;
                newChangeset[CHANGES] = mergedChanges;
                newChangeset._notifyVirtualProperties();
                return newChangeset;
            },
            /**
             * Returns the changeset to its pristine state, and discards changes and
             * errors.
             *
             * @method rollback
             */
            rollback() {
                // Get keys before reset.
                let c = this;
                let keys = c._rollbackKeys();
                // Reset.
                Ember.set(this, CHANGES, {});
                Ember.set(this, ERRORS, {});
                c._notifyVirtualProperties(keys);
                c.trigger(AFTER_ROLLBACK_EVENT);
                return this;
            },
            /**
             * Discards any errors, keeping only valid changes.
             *
             * @public
             * @chainable
             * @method rollbackInvalid
             * @param {String} key optional key to rollback invalid values
             * @return {Changeset}
             */
            rollbackInvalid(key) {
                let errorKeys = keys(Ember.get(this, ERRORS));
                if (key) {
                    this._notifyVirtualProperties([key]);
                    this._deleteKey(ERRORS, key);
                    if (errorKeys.indexOf(key) > -1) {
                        this._deleteKey(CHANGES, key);
                    }
                } else {
                    this._notifyVirtualProperties();
                    Ember.set(this, ERRORS, {});
                    // if on CHANGES hash, rollback those as well
                    errorKeys.forEach(errKey => {
                        this._deleteKey(CHANGES, errKey);
                    });
                }
                return this;
            },
            /**
             * Discards changes/errors for the specified properly only.
             *
             * @public
             * @chainable
             * @method rollbackProperty
             * @param {String} key key to delete off of changes and errors
             * @return {Changeset}
             */
            rollbackProperty(key) {
                this._deleteKey(CHANGES, key);
                this._deleteKey(ERRORS, key);
                return this;
            },
            /**
             * Validates the changeset immediately against the validationMap passed in.
             * If no key is passed into this method, it will validate all fields on the
             * validationMap and set errors accordingly. Will throw an error if no
             * validationMap is present.
             *
             * @method validate
             */
            validate(key) {
                if (keys(validationMap).length === 0) {
                    return Ember.RSVP.resolve(null);
                }
                if (Ember.isNone(key)) {
                    let maybePromise = keys(validationMap).map(validationKey => {
                        return this._validateKey(validationKey, this._valueFor(validationKey));
                    });
                    return Ember.RSVP.all(maybePromise);
                }
                return Ember.RSVP.resolve(this._validateKey(key, this._valueFor(key)));
            },
            /**
             * Manually add an error to the changeset. If there is an existing
             * error or change for `key`, it will be overwritten.
             *
             * @method addError
             */
            addError(key, error) {
                // Construct new `Err` instance.
                let newError;
                if ((0, _isObject.default)(error)) {
                    (false && !(error.hasOwnProperty('value')) && Ember.assert('Error must have value.', error.hasOwnProperty('value')));
                    (false && !(error.hasOwnProperty('validation')) && Ember.assert('Error must have validation.', error.hasOwnProperty('validation')));

                    newError = new _err.default(error.value, error.validation);
                } else {
                    newError = new _err.default(Ember.get(this, key), error);
                }
                // Add `key` to errors map.
                let errors = Ember.get(this, ERRORS);
                (0, _setNestedProperty.default)(errors, key, newError);
                this.notifyPropertyChange(ERRORS);
                // Notify that `key` has changed.
                this.notifyPropertyChange(key);
                // Return passed-in `error`.
                return error;
            },
            /**
             * Manually push multiple errors to the changeset as an array.
             *
             * @method pushErrors
             */
            pushErrors(key, ...newErrors) {
                let errors = Ember.get(this, ERRORS);
                let existingError = errors[key] || new _err.default(null, []);
                let validation = existingError.validation;
                let value = Ember.get(this, key);
                if (!Ember.isArray(validation) && Ember.isPresent(validation)) {
                    existingError.validation = [validation];
                }
                let v = existingError.validation;
                validation = [...v, ...newErrors];
                let newError = new _err.default(value, validation);
                (0, _setNestedProperty.default)(errors, key, newError);
                this.notifyPropertyChange(ERRORS);
                this.notifyPropertyChange(key);
                return { value, validation };
            },
            /**
             * Creates a snapshot of the changeset's errors and changes.
             *
             * @method snapshot
             */
            snapshot() {
                let changes = Ember.get(this, CHANGES);
                let errors = Ember.get(this, ERRORS);
                return {
                    changes: keys(changes).reduce((newObj, key) => {
                        newObj[key] = changes[key].value;
                        return newObj;
                    }, {}),
                    errors: keys(errors).reduce((newObj, key) => {
                        let e = errors[key];
                        newObj[key] = { value: e.value, validation: e.validation };
                        return newObj;
                    }, {})
                };
            },
            /**
             * Restores a snapshot of changes and errors. This overrides existing
             * changes and errors.
             *
             * @method restore
             */
            restore({ changes, errors }) {
                (0, _validateNestedObj.default)('snapshot.changes', changes);
                (0, _validateNestedObj.default)('snapshot.errors', errors);
                let newChanges = keys(changes).reduce((newObj, key) => {
                    newObj[key] = new _change.default(changes[key]);
                    return newObj;
                }, {});
                let newErrors = keys(errors).reduce((newObj, key) => {
                    let e = errors[key];
                    newObj[key] = new _err.default(e.value, e.validation);
                    return newObj;
                }, {});
                Ember.set(this, CHANGES, newChanges);
                Ember.set(this, ERRORS, newErrors);
                this._notifyVirtualProperties();
                return this;
            },
            /**
             * Unlike `Ecto.Changeset.cast`, `cast` will take allowed keys and
             * remove unwanted keys off of the changeset. For example, this method
             * can be used to only allow specified changes through prior to saving.
             *
             * @method cast
             */
            cast(allowed = []) {
                let changes = Ember.get(this, CHANGES);
                if (Ember.isArray(allowed) && allowed.length === 0) {
                    return this;
                }
                let changeKeys = keys(changes);
                let validKeys = Ember.A(changeKeys).filter(key => (0, _includes.default)(allowed, key));
                let casted = (0, _take.default)(changes, validKeys);
                Ember.set(this, CHANGES, casted);
                return this;
            },
            /**
             * Checks to see if async validator for a given key has not resolved.
             * If no key is provided it will check to see if any async validator is running.
             *
             * @method isValidating
             */
            isValidating(key) {
                let runningValidations = Ember.get(this, RUNNING_VALIDATIONS);
                let ks = Ember.A(keys(runningValidations));
                if (key) {
                    return (0, _includes.default)(ks, key);
                }
                return !Ember.isEmpty(ks);
            },
            /**
             * Validates a specific key
             *
             * @method _validateKey
             * @private
             */
            _validateKey(key, value) {
                let content = Ember.get(this, CONTENT);
                let oldValue = Ember.get(content, key);
                let validation = this._validate(key, value, oldValue);
                this.trigger(BEFORE_VALIDATION_EVENT, key);
                // TODO: Address case when Promise is rejected.
                if ((0, _isPromise.default)(validation)) {
                    this._setIsValidating(key, true);
                    return validation.then(resolvedValidation => {
                        this._setIsValidating(key, false);
                        this.trigger(AFTER_VALIDATION_EVENT, key);
                        return this._handleValidation(resolvedValidation, { key, value });
                    });
                }
                let result = this._handleValidation(validation, { key, value });
                this.trigger(AFTER_VALIDATION_EVENT, key);
                return result;
            },
            /**
             * Takes resolved validation and adds an error or simply returns the value
             *
             * @method _handleValidation
             * @private
             */
            _handleValidation(validation, { key, value }) {
                let isValid = validation === true || Ember.isArray(validation) && validation.length === 1 && validation[0] === true;
                // Happy path: remove `key` from error map.
                this._deleteKey(ERRORS, key);
                // Error case.
                if (!isValid) {
                    return this.addError(key, { value, validation });
                }
                return value;
            },
            /**
             * runs the validator with the key and value
             *
             * @method _validate
             * @private
             */
            _validate(key, newValue, oldValue) {
                let validator = Ember.get(this, VALIDATOR);
                let content = Ember.get(this, CONTENT);
                if (typeof validator === 'function') {
                    let isValid = validator({
                        key,
                        newValue,
                        oldValue,
                        changes: Ember.get(this, 'change'),
                        content
                    });
                    return Ember.isPresent(isValid) ? isValid : true;
                }
                return true;
            },
            /**
             * Sets property or error on the changeset.
             * Returns value or error
             */
            _setProperty({ key, value, oldValue }) {
                let changes = Ember.get(this, CHANGES);
                // Happy path: update change map.
                if (!Ember.isEqual(oldValue, value)) {
                    (0, _setNestedProperty.default)(changes, key, new _change.default(value));
                } else if (key in changes) {
                    this._deleteKey(CHANGES, key);
                }
                // Happy path: notify that `key` was added.
                this.notifyPropertyChange(CHANGES);
                this.notifyPropertyChange(key);
            },
            /**
             * Increment or decrement the number of running validations for a
             * given key.
             */
            _setIsValidating(key, value) {
                let running = Ember.get(this, RUNNING_VALIDATIONS);
                let count = Ember.get(running, key) || 0;
                if (!value && count === 1) {
                    delete running[key];
                    return;
                }
                (0, _emberDeepSet.default)(running, key, value ? count + 1 : count - 1);
            },
            /**
             * Value for change or the original value.
             */
            _valueFor(key) {
                let changes = Ember.get(this, CHANGES);
                let errors = Ember.get(this, ERRORS);
                let content = Ember.get(this, CONTENT);
                if (errors.hasOwnProperty(key)) {
                    let e = errors[key];
                    return e.value;
                }
                let original = Ember.get(content, key);
                if (changes.hasOwnProperty(key)) {
                    let c = changes[key];
                    return c.value;
                }
                // nested thus circulate through `value` and see if match
                if (key.indexOf('.') !== -1) {
                    let [baseKey, ...keyParts] = key.split('.');
                    if (changes.hasOwnProperty(baseKey)) {
                        let { value } = changes[baseKey];
                        // make sure to return value if not object
                        if (!value) {
                            return value;
                        }
                        let result = Ember.get(value, keyParts.join('.'));
                        if (result) {
                            return result;
                        }
                    }
                }
                return original;
            },
            /**
             * Notifies virtual properties set on the changeset of a change.
             * You can specify which keys are notified by passing in an array.
             *
             * @private
             * @param {Array} keys
             * @return {Void}
             */
            _notifyVirtualProperties(keys) {
                if (!keys) {
                    keys = this._rollbackKeys();
                }
                (keys || []).forEach(key => this.notifyPropertyChange(key));
            },
            /**
             * Gets the changes and error keys.
             */
            _rollbackKeys() {
                let changes = Ember.get(this, CHANGES);
                let errors = Ember.get(this, ERRORS);
                return Ember.A([...keys(changes), ...keys(errors)]).uniq();
            },
            /**
             * Deletes a key off an object and notifies observers.
             */
            _deleteKey(objName, key = '') {
                let obj = Ember.get(this, objName);
                if (obj.hasOwnProperty(key)) {
                    delete obj[key];
                }
                let c = this;
                c.notifyPropertyChange(`${objName}.${key}`);
                c.notifyPropertyChange(objName);
            },
            get(key) {
                if (key.indexOf('.') > -1) {
                    // pull off changes hash with full key instead of
                    // breaking up key
                    return this.unknownProperty(key);
                } else {
                    return this._super(...arguments);
                }
            },
            set(key, value) {
                if (key.indexOf('.') > -1) {
                    // Adds new CHANGE and avoids ember intenals setting directly on model
                    // TODO: overriding `set(changeset, )` doesnt work
                    return this.setUnknownProperty(key, value);
                } else {
                    return this._super(...arguments);
                }
            }
        };
        return Ember.Object.extend(Ember.Evented, changeset);
    }
    class Changeset {
        /**
         * Changeset factory
         *
         * @class Changeset
         * @constructor
         */
        constructor(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
            return changeset(obj, validateFn, validationMap, options).create();
        }
    }
    exports.default = Changeset;
});