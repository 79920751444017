define('ember-changeset/utils/computed/object-equal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = objectEqual;

    const { keys } = Object;
    /**
     * Shallow object comparison computed property. Checks all key/value pairs on
     * the first object and compares against the second object. Essentially, this
     * means that the second object must have the same key/values as the first, but
     * not vice versa.
     *
     * @public
     * @param  {string} sourceKey dependent key for first object
     * @param  {string} compareKey dependent key for second object
     * @return {boolean}
     */
    function objectEqual(sourceKey, compareKey) {
        return Ember.computed(sourceKey, compareKey, function () {
            let source = Ember.get(this, sourceKey);
            let compare = Ember.get(this, compareKey);
            return keys(source).reduce((acc, key) => acc && Ember.get(source, key) === Ember.get(compare, key), true);
        }).readOnly();
    }
});