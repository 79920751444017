define('ember-cli-file-saver/mixins/file-saver', ['exports', 'file-saver'], function (exports, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    saveCanvasAs(filename, canvas) {
      canvas.toBlob(blob => this.saveAs(blob, filename));
    },

    saveTextAs(filename, text) {
      this.saveAs(new Blob([text], { type: 'text/plain;charset=utf-8' }), filename);
    },

    saveFileAs(filename, content, contentType) {
      this.saveAs(new File([content], filename, { type: contentType }));
    },

    saveAs() {
      _fileSaver.default.saveAs(...arguments);
    }

  });
});