define("ember-composability-tools/components/root", ["exports", "@ember/component", "@ember/template-factory", "ember-composability-tools/components/node"], function (_exports, _component, _templateFactory, _node) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (element this.tagName) as |Tag|}}
    <Tag
      {{did-insert this.didInsertNode}}
      {{will-destroy this.willDestroyNode}}
      ...attributes
    >
      {{yield (component "node" parent=this)}}
    </Tag>
  {{/let}}
  */
  {
    "id": "Pls/CxUx",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[30,0,[\"tagName\"]]],null]],null],0,null,[[\"tagName\"],[[30,0,[\"tagName\"]]]]]],[[[1,\"  \"],[8,[30,1],[[17,2],[4,[38,4],[[30,0,[\"didInsertNode\"]]],null],[4,[38,5],[[30,0,[\"willDestroyNode\"]]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,3,[[50,\"node\",0,null,[[\"parent\"],[[30,0]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"Tag\",\"&attrs\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"-element\",\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-composability-tools/components/root.hbs",
    "isStrictMode": false
  });

  class Root extends _node.default {
    get tagName() {
      return this.args.tagName || 'div';
    }

  }

  _exports.default = Root;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Root);
});