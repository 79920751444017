define("ember-changeset/utils/object-without", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = objectWithout;
    const { keys } = Object;
    /**
     * Merges all sources together, excluding keys in excludedKeys.
     *
     * @param  {string[]}    excludedKeys
     * @param  {...object}        sources
     *
     * @return {object}
     */
    function objectWithout(excludedKeys, ...sources) {
        return sources.reduce((acc, source) => {
            keys(source).filter(key => excludedKeys.indexOf(key) === -1 || !source.hasOwnProperty(key)).forEach(key => acc[key] = source[key]);
            return acc;
        }, {});
    }
});