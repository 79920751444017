define('ember-burger-menu/-private/state', ['exports', 'ember-burger-menu/animations'], function (exports, _animations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    open: false,
    locked: false,
    width: 300,
    position: 'left',
    animation: 'slide',

    minSwipeDistance: 150,
    maxSwipeTime: 300,

    itemAnimation: null,
    customAnimation: null,

    styles: Ember.computed('animation', 'itemAnimation', 'customAnimation', function () {
      let animation = this.get('animation');
      let itemAnimation = this.get('itemAnimation');
      let customAnimation = this.get('customAnimation');

      return (0, _animations.default)(customAnimation || animation, itemAnimation).create();
    }).readOnly(),

    actions: Ember.computed(function () {
      return {
        open: () => !this.get('locked') && this.set('open', true),
        close: () => !this.get('locked') && this.set('open', false),
        toggle: () => !this.get('locked') && this.toggleProperty('open')
      };
    }).readOnly()
  });
});