define("ember-leaflet/services/ember-leaflet", ["exports", "@ember/service", "@ember/debug"], function (_exports, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class EmberLeafletService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "components", []);
    }

    registerComponent(name, options = {}) {
      let as = options.as || name;
      (false && !(this.components.find(c => c.name === name || c.as === as) === undefined) && (0, _debug.assert)(`Tried to register component \`${name}\` as \`${as}\`, but it was already registered. Try to register a different component or register it under a different name.`, this.components.find(c => c.name === name || c.as === as) === undefined));
      this.components.push({
        name,
        as
      });
    }

  }

  _exports.default = EmberLeafletService;
});