define('ember-cli-flash/utils/computed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = add;
  exports.guidFor = guidFor;
  function add(...dependentKeys) {
    const computedFunc = Ember.computed({
      get() {
        const values = dependentKeys.map(dependentKey => {
          const value = Ember.get(this, dependentKey);

          if (Ember.typeOf(value) !== 'number') {
            return;
          }

          return value;
        });

        return Ember.A(values).compact().reduce((prev, curr) => {
          return prev + curr;
        });
      }
    });

    return computedFunc.property.apply(computedFunc, dependentKeys);
  }

  function guidFor(dependentKey) {
    return Ember.computed(dependentKey, {
      get() {
        const value = Ember.get(this, dependentKey);

        return Ember.guidFor(value.toString());
      }
    });
  }
});