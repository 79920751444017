define('ember-changeset/helpers/changeset-set', ['exports', 'ember-changeset/utils/is-changeset'], function (exports, _isChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.changesetSet = changesetSet;

    /**
     * This is a drop in replacement for the `mut` helper
     *
     * @method changesetSet
     * @param params
     */
    function changesetSet([obj, path]) {
        if ((0, _isChangeset.default)(obj)) {
            return value => {
                return obj.set(path, value);
            };
        }
    }
    exports.default = Ember.Helper.helper(changesetSet);
});