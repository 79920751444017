define("ember-table/components/ember-th/sort-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "exUM5FyK",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,1],[15,0,[29,[\"et-sort-indicator \",[52,[33,2],\"is-ascending\",\"is-descending\"]]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,[[33,5]]],[1,\"\\n\"]],[]],[[[41,[33,6],[[[1,\"        \"],[1,[34,7]],[1,\"\\n\"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,8],[[[1,\"  \"],[10,\"button\"],[14,0,\"et-sort-toggle et-speech-only\"],[12],[1,\"Toggle Sort\"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"isSorted\",\"isSortedAsc\",\"has-block\",\"yield\",\"columnMeta\",\"isMultiSorted\",\"sortIndex\",\"isSortable\"]]",
    "moduleName": "ember-table/components/ember-th/sort-indicator/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});