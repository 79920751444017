define('ember-changeset/utils/assign', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = pureAssign;
    function pureAssign(...objects) {
        return Ember.assign({}, ...objects);
    }
});