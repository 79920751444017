define("ember-leaflet-marker-cluster/components/marker-cluster-layer", ["exports", "ember-leaflet/components/base-layer"], function (_exports, _baseLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MarkerClusterLayer extends _baseLayer.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "leafletOptions", [...this.leafletOptions, 'showCoverageOnHover', 'zoomToBoundsOnClick', 'spiderfyOnMaxZoom', 'removeOutsideVisibleBounds', 'animate', 'animateAddingMarkers', 'disableClusteringAtZoom', 'maxClusterRadius', 'polygonOptions', 'singleMarkerMode', 'spiderLegPolylineOptions', 'spiderfyDistanceMultiplier', 'iconCreateFunction']);

      _defineProperty(this, "leafletEvents", [...this.leafletEvents, // Marker clutster events
      'clusterclick', 'clusterdblclick', 'clustermousedown', 'clustermouseover', 'clustermouseout', 'clustercontextmenu', 'clusteradd', 'clusterremove', 'animationend', 'spiderfied', 'unspiderfied', // Marker events
      'click', 'dblclick', 'mousedown', 'mouseover', 'mouseout', 'contextmenu', 'dragstart', 'drag', 'dragend', 'move', 'remove', 'add', 'popupopen', 'popupclose']);

      _defineProperty(this, "componentsToYield", [...this.componentsToYield, {
        name: 'marker-layer',
        as: 'marker'
      }, {
        name: 'circle-marker-layer',
        as: 'circle-marker'
      }]);
    }

    createLayer() {
      return this.L.markerClusterGroup(...this.requiredOptions, this.options);
    }

  }

  _exports.default = MarkerClusterLayer;
});