define('ember-local-storage/serializers/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    JSONAPISerializer
  } = _emberData.default;

  exports.default = JSONAPISerializer.extend({
    // Serialization behavior
    _shouldSerializeHasMany: function () {
      return true;
    },
    shouldSerializeHasMany: function () {
      return true;
    }
  });
});