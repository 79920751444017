define("ember-changeset/utils/take", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = take;
    function take(originalObj = {}, keysToTake = []) {
        let newObj = {};
        for (let key in originalObj) {
            if (keysToTake.indexOf(key) !== -1) {
                newObj[key] = originalObj[key];
            }
        }
        return newObj;
    }
});