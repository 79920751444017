define('ember-local-storage/mixins/adapters/import-export', ['exports', 'ember-local-storage/helpers/import-export'], function (exports, _importExport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    Mixin
  } = Ember;

  exports.default = Mixin.create({
    importData(store, content, options) {
      return (0, _importExport.importData)(store, content, options);
    },

    exportData(store, types, options) {
      return (0, _importExport.exportData)(store, types, options);
    }
  });
});