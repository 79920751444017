define("ember-lifeline/dom-event-listeners", ["exports", "ember-lifeline/utils/disposable"], function (_exports, _disposable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEventListener = addEventListener;
  _exports.removeEventListener = removeEventListener;
  _exports.PASSIVE_SUPPORTED = void 0;

  /**
   * A map of instances/listeners that allows us to
   * store listener references per instance.
   *
   * @private
   *
   */
  const eventListeners = new WeakMap();

  const PASSIVE_SUPPORTED = (() => {
    let ret = false;

    try {
      let options = Object.defineProperty({}, 'passive', {
        get() {
          ret = true;
        }

      });
      window.addEventListener('test', null, options);
    } catch (err) {// intentionally empty
    }

    return ret;
  })();

  _exports.PASSIVE_SUPPORTED = PASSIVE_SUPPORTED;
  const LISTENER_ITEM_LENGTH = 5;
  var ListenerItemPosition;

  (function (ListenerItemPosition) {
    ListenerItemPosition[ListenerItemPosition["Target"] = 0] = "Target";
    ListenerItemPosition[ListenerItemPosition["eventName"] = 1] = "eventName";
    ListenerItemPosition[ListenerItemPosition["callback"] = 2] = "callback";
    ListenerItemPosition[ListenerItemPosition["originalCallback"] = 3] = "originalCallback";
    ListenerItemPosition[ListenerItemPosition["options"] = 4] = "options";
  })(ListenerItemPosition || (ListenerItemPosition = {}));
  /**
     Attaches an event listener that will automatically be removed when the host
     object is dropped from DOM.
  
     Example:
  
     ```js
     import Component from 'ember-component';
     import { addEventListener } from 'ember-lifeline';
  
     export default Component.extend({
       didInsertElement() {
         addEventListener(this, '.some-item', 'click', (e) => {
           console.log('.some-item was clicked');
         });
       }
     });
     ```
  
     This can also be used in other ember types like services and controllers. In
     order to use it there an html element reference must be used instead of a
     css selector. This way we can be sure the element actually exists when the
     listener is attached:
  
     ```js
     import Service from 'ember-service';
     import { addEventListener } from 'ember-lifeline';
  
     export default Service.extend({
       init() {
         this._super(...arguments);
         const el = document.querySelector('.foo');
         addEventListener(this, el, 'click');
       }
     });
     ```
  
     @public
     @method addEventListener
     @param { IDestroyable } destroyable the instance to attach the listener for
     @param { EventTarget } target the EventTarget, e.g. DOM element or `window`
     @param { String } eventName the event name to listen for
     @param { Function } callback the callback to run for that event
     @param { any } options additional options provided to the browser's `addEventListener`
     */


  function addEventListener(destroyable, target, eventName, callback, options) {
    assertArguments(target, eventName, callback);

    let _callback = Ember.run.bind(destroyable, callback);

    let listeners = eventListeners.get(destroyable);

    if (listeners === undefined) {
      listeners = [];
      eventListeners.set(destroyable, listeners);
    } // Register a disposable every time we go from zero to one.


    if (listeners.length === 0) {
      (0, _disposable.registerDisposable)(destroyable, getEventListenersDisposable(listeners));
    }

    if (!PASSIVE_SUPPORTED) {
      options = undefined;
    }

    target.addEventListener(eventName, _callback, options);
    listeners.push(target, eventName, _callback, callback, options);
  }
  /**
     Removes an event listener explicitly from the target, also removing it from
     lifeline's DOM entanglement tracking.
  
     @public
     @param { IDestroyable } destroyable the instance to remove the listener for
     @param { EventTarget } target the EventTarget, e.g. DOM element or `window`
     @param { String } eventName the event name to listen for
     @param { Function } callback the callback to run for that event
     @param { any } options additional options provided to the browser's `removeEventListener`
     */


  function removeEventListener(destroyable, target, eventName, callback, options) {
    assertArguments(target, eventName, callback);
    let listeners = eventListeners.get(destroyable);

    if (listeners === undefined || listeners.length === 0) {
      return;
    }

    if (!PASSIVE_SUPPORTED) {
      options = undefined;
    } // We cannot use Array.findIndex as we cannot rely on babel/polyfill being present


    for (let i = 0; i < listeners.length; i += LISTENER_ITEM_LENGTH) {
      if (listeners[i + ListenerItemPosition.Target] === target && listeners[i + ListenerItemPosition.eventName] === eventName && listeners[i + ListenerItemPosition.originalCallback] === callback) {
        /*
         * Drop the event listener and remove the listener object
         */
        let ownCallback = listeners[i + ListenerItemPosition.callback];
        target.removeEventListener(eventName, ownCallback, options);
        listeners.splice(i, LISTENER_ITEM_LENGTH);
        break;
      }
    }
  }

  function assertArguments(element, eventName, callback) {
    (false && !(!!element) && Ember.assert('Must provide a DOM element', !!element));
    (false && !(typeof element !== 'string' && typeof element !== 'function' && element !== null && element !== undefined) && Ember.assert('Must provide an element (not a DOM selector)', typeof element !== 'string' && typeof element !== 'function' && element !== null && element !== undefined));
    (false && !(!!eventName) && Ember.assert('Must provide an eventName that specifies the event type', !!eventName));
    (false && !(!!callback) && Ember.assert('Must provide a callback to run for the given event name', !!callback));
  }

  function getEventListenersDisposable(listeners) {
    return function () {
      if (listeners !== undefined) {
        /* Drop non-passive event listeners */
        for (let i = 0; i < listeners.length; i += LISTENER_ITEM_LENGTH) {
          let element = listeners[i + ListenerItemPosition.Target];
          let eventName = listeners[i + ListenerItemPosition.eventName];
          let callback = listeners[i + ListenerItemPosition.callback];
          let options = listeners[i + ListenerItemPosition.options];
          element.removeEventListener(eventName, callback, options);
        }

        listeners.length = 0;
      }
    };
  }
});