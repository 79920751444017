define('ember-changeset/helpers/changeset-get', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const CONTENT = '_content';
    const CHANGES = '_changes';
    class ChangesetGet extends Ember.Helper.extend({
        invalidate: Ember.observer(`changeset.${CONTENT}`, `changeset.${CHANGES}`, function () {
            this.recompute();
        })
    }) {
        constructor() {
            super(...arguments);
            this.changeset = null;
        }
        compute([changeset, fieldPath]) {
            if (this.changeset === null) {
                this.set('changeset', changeset);
            }
            if (!this.changeset) {
                return;
            }
            return this.changeset.get(fieldPath);
        }
    }
    exports.default = ChangesetGet;
});