define("ember-leaflet/components/rectangle-layer", ["exports", "ember-leaflet/components/polygon-layer"], function (_exports, _polygonLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A class for drawing rectangle overlays on a map.
   *
   * @class RectangleLayer
   * @extends PolygonLayer
   */
  class RectangleLayer extends _polygonLayer.default {
    createLayer() {
      return this.L.rectangle(...this.requiredOptions, this.options);
    }

  }

  _exports.default = RectangleLayer;
});